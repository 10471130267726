<script setup lang="ts">
import { upperFirst } from 'lodash-es';
import { camelCase } from 'lodash-es';
const props = defineProps({
  svgName: {
    type: String,
    required: true,
  },
});
const AsyncComp = defineAsyncComponent(
  () => /* @vite-ignore */ import(`../components/${upperFirst(camelCase(props.svgName))}.vue`)
);
</script>

<template>
  <div class="svg-comp-wrapper flex" style="height: auto">
    <LazyClientOnly>
      <component :is="AsyncComp" />
    </LazyClientOnly>
  </div>
</template>
